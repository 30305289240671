import React from "react"
import { Link } from "gatsby"
import InstagramPreview from '../components/instagram/instagramPreview.js';
import Layout from "../components/layout"
import SEO from "../components/seo"

const MediaPage = () => (
    <Layout>
        <SEO title="Media" />
        <InstagramPreview />
    </Layout>
)

export default MediaPage

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

var stylez = {
    width: '33%',
    maxWidth: '33%',
    height: 'auto',
    display: 'inline-block',
    textAlign: 'center'
}
var igImgstylez = {
    width: 'auto',
    // height: '27.5vh',
    display: 'inline-block',
    // maxHeight: '191.4px',
    // maxWidth: '191.4px'
}

const InstagramPreview = () => (

    <StaticQuery
        query={graphql`
    query InstagramPosts {
      instagram: allInstaNode(sort: {fields: timestamp, order: DESC}, limit: 2400) {
        edges {
          node {
            caption
            id
            preview
            thumbnails {
              config_width
              config_height
                }
                
          }
        }
      }
    }
    `}
        render={(data) => (
            <div className={'styles.igContainer'}>
                {
                    data.instagram.edges.map((item, i) => (
                        item.node ? (
                            <div key={i} style={stylez} className={'styles.igContainer'}>
                                <img
                                    src={item.node.preview}
                                    alt={item.node.caption}
                                    className={'styles.igImg'}
                                    style={igImgstylez}
                                />
                            </div>
                        ) : (<div />)
                    ))
                }
            </div >
        )}
    />
)

export default InstagramPreview